<template>
  <div class="live-list">
    <TopBar :type="2" :pageTitle="'直播课堂'" />
    <swiper class="m-banner" ref="bannerSwiper" :options="swiperOptionsBanner">
      <swiper-slide v-for="(item, index) in liveModule" :key="item.image+'_'+index">
        <router-link class="banner-img" :to="'/live/'+item.channel_no" :style="'background: url('+item.image+') no-repeat;background-size: 100% 100%;'">
          <div class="bg" v-if="item.liveNow">
            <div class="live-now">正在直播</div>
            <img class="play-img" :src="require('@/assets/play2-ico.png')">
            <p class="name">{{item.name}}</p>
          </div>
        </router-link>
      </swiper-slide>
      <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
    <ul class="course-list">
      <li class="course-info" v-for="item in liveModule" :key="item.id">
        <div class="date">{{item.live_date}}</div>
        <div class="course-content">
          <img :src="item.image" class="course-img">
          <div class="right">
            <p class="title vertical-ellipsis">{{item.name}}</p>
            <p class="intro vertical-ellipsis">{{item.intro}}</p>
          </div>
        </div>
        <router-link :to="'/live/'+item.channel_no+'/'+item.id" class="enter-live">进入直播</router-link>
      </li>
    </ul>
    <button class="more" v-if="showMore">历史直播</button>
    <div class="line" v-if="showMore"></div>
    <ul class="course-list">
      <li class="course-info" v-for="item in courseList.data" :key="item.id">
        <div class="date">{{item.created_at}}</div>
        <div class="course-content">
          <img :src="item.image" class="course-img">
          <div class="right">
            <p class="title vertical-ellipsis">{{item.name}}</p>
            <p class="intro vertical-ellipsis">{{item.intro}}</p>
          </div>
          <router-link :to="'/recordedInfo/'+item.id" class="enter-live">查看回放</router-link>
        </div>
      </li>
    </ul>
    <div
      class="nodata"
      v-if="
        liveModule.length == 0 &&
        courseList.length == 0
      "
    >
      <img class="nodata-img" :src="require('@/assets/no-data.png')" />
      <p class="nodata-text">暂无数据</p>
    </div>
    <!-- <p class="bottom-line" v-if="more">我是有底线的</p> -->
  </div>
</template>
<script>
import TopBar from '@/m/common/topBar.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
export default {
  name: 'liveList',
  data(){
    return {
      more: false,
      showMore: false,
      liveModule: [],  //直播列表
      courseList: [],  //回放
      liveSelect: '',
      swiperOptionsBanner: {
        slidesPerView: 1.18,  //显示slide的数量
        centeredSlides: true, //居中显示
        spaceBetween: 14,     //间距
        loop: true,           //循环
        observer:true,        //修改swiper自己或子元素时，自动初始化swiper 
        observeParents:true,  //修改swiper的父元素时，自动初始化swiper 
        autoplay: {
          delay: 3000,
          stopOnLastSlide: false,
          disableOnInteraction: true,
        },
        loop: true,
        //setWrapperSize :true,  // Swiper使用flexbox布局(display: flex)，
        pagination: {
          el: '.swiper-pagination',
          clickable: true, //是否可点击
        }
      },
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    TopBar
  },
  mounted(){
    this.getLiveList();  //获取直播列表
    this.getCourseList();//获取回放
  },
  methods: {
    //获取直播列表
    getLiveList(){
      this.$axios.post(`/v1/live/list`).then(res => {
        let nowDta = Math.floor(new Date().getTime()/1000);
        for(let i=0; i<res.data.length; i++){
          if(nowDta>res.data[i].live_time && nowDta<res.data[i].end_time){
            res.data[i].liveNow = true;
          }else{
            res.data[i].liveNow = false;
          }
        }

        this.liveModule = res.data;
        this.liveSelect = res.data[0];
      })
    },
    //获取回放
    getCourseList(){
      this.$axios.post(`/v1/live/courseList`).then(res => {
        this.courseList = res.data.data;
        if(res.data.data.length>0){
          this.showMore = true;
        }
      })
    },
    selectLive(data){
      this.liveSelect = data;
    },
  }
}
</script>
<style scoped lang="scss">
.m-banner{
  padding-top: 28px;
}
.m-banner,
::v-deep .swiper-wrapper{
  height: 278px;
  .banner-img{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    .bg{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.6);
      .live-now{
        height: 30px;
        line-height: 30px;
        padding-left: 36px;
        font-size: 28px;
        color: #F5A623;
        background: url(~@/assets/live-now-ico.png) left center no-repeat;
        background-size: auto 100%;
        margin: 14px 0 0 10px;
      }
      .play-img{
        width: auto;
        height: 54px;
        position: absolute;
        top: 50%;
        left: 50%;
        margin: -27px 0 0 -27px;
      }
      .name{
        width: 100%;
        position: absolute;
        left: 0;
        bottom: 14px;
        font-size: 26px;
        color: #FFF;
        text-align: center;
      }
    }
  }
  .swiper-slide{
    display: flex;
    align-items: center;
    .banner-img{
      display: block;
      width: 750px;
      height: 278px;
      border-radius: 6px;
      overflow: hidden;
      transition: width 0.3s, height 0.3s;
      -webkit-transition: width 0.3s, height 0.3s; /* Safari */
    }
  }
  .swiper-slide-prev,
  .swiper-slide-next{
    .banner-img{
      display: block;
      width: 685px;
      height: 254px;
      transition: width 0.3s, height 0.3s;
      -webkit-transition: width 0.3s, height 0.3s; /* Safari */
    }
  }
  ::v-deep .swiper-pagination{
    .swiper-pagination-bullet{
      width: 32px;
      height: 4px;
      background-color: #ccc;
      border-radius: 2px;
      opacity: 1;
      margin: 0 5px;
    }
    .swiper-pagination-bullet-active{
      background-color: #254ED4;
    }
  }
}
.course-list{
  padding: 30px;
}
.course-info{
  padding: 28px;
  box-shadow: 0px 0px 12px 0px rgba(0, 12, 40, 0.14);
  border-radius: 6px;
  margin-bottom: 24px;
  overflow: hidden;
  position: relative;
  .date{
    display: inline-block;
    height: 24px;
    font-size: 22px;
    color: #999;
    line-height: 24px;
    padding: 5px 14px 5px 34px;
    background: url(~@/assets/clock-ico.png) left center no-repeat #F5F5F5;
    background-size: 24px auto;
    border-radius: 6px;
    margin-bottom: 20px;
  }
  .course-img{
    width: 242px;
    height: 180px;
    border-radius: 8px;
    float: left;
  }
  .right{
    width: 370px;
    float: right;
    .title{
      height: 38px;
      font-size: 28px;
      color: #333;
      line-height: 38px;
    }
    .intro{
      font-size: 24px;
      margin-top: 14px;
      color: #999;
    }
  }
  .enter-live{
    display: block;
    padding: 8px 16px 8px 46px;
    text-align: center;
    border-radius: 6px;
    font-size: 26px;
    color: #FFF;
    background: url(~@/assets/video-ico.png) 12px center no-repeat #F5A623;
    background-size: 25px auto;
    position: absolute;
    right: 28px;
    bottom: 28px;
  }
}
.line{
  width: 100%;
  height: 10px;
  background: #F5F5F5;
}
.bottom-line{
  width: 100%;
  font-size: 28px;
  color: #999;
  text-align: center;
  padding-bottom: 48px;
}
.more{
  display: block;
  width: 316px;
  height: 48px;
  border-radius: 6px;
  border: 1px solid #E5E5E5;
  background-color: #fff;
  margin: 0 auto 33px;
  font-size: 28px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  &::after{
    content: '';
    display: block;
    width: 24px;
    height: 24px;
    background: url(~@/assets/arrows-down-ico.png) no-repeat;
    background-size: 100% auto;
    margin-left: 6px;
  }
}
.nodata {
  width: 574px;
  padding-top: 20px;
  margin: 0 auto;
  text-align: center;
  .nodata-img {
    width: 100%;
    height: auto;
  }
  .nodata-text {
    font-size: 26px;
    color: #999;
    padding-top: 40px;
  }
}
</style>